/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import {
  Container,
  Button,
  CircularProgress,
  circularProgressClasses
} from '@mui/material';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Icon } from '@bayada/shared/ui-components';
import { findAnOfficePageProps, serviceDataProps } from '../page';
import OfficesList from './offices-list';
import OfficeMap from './offices-map';

export interface officeMapContainerProps {
  officeData: any;
  loading: boolean;
  officePageData: findAnOfficePageProps | null;
  initialPlace: any;
  selectedService?: serviceDataProps;
  address?: string;
  service?: string;
  id?: string;
}

/**
 * Represents a map displaying offices and their details.
 * @param {object} props - The props object containing component properties.
 * @param {object[]} props.officeData - An array of objects containing information about offices.
 * @param {object} props.officePageData - An object containing data for the office page.
 * @param {boolean} props.loading - Indicates whether the data is loading.
 * @param {object} props.initialPlace - The initial place for the map.
 * @param {string[]} props.selectedService - An array of selected services.
 * @returns {JSX.Element} A JSX element representing the OfficeMap component.
 */
// eslint-disable-next-line react/display-name
const OfficeMapContainer = forwardRef(
  (props: officeMapContainerProps, ref: any) => {
    const {
      officeData,
      officePageData,
      loading,
      initialPlace,
      selectedService,
      id,
      address,
      service
    } = props || {};

    const [showOfficeDetails, setShowOfficeDetails] = useState(true);

    /**
     * Toggles the visibility of office details.
     * @returns {void}
     */
    const handleButtonClick = () => {
      setShowOfficeDetails(!showOfficeDetails);
    };

    const googleMapContainerRef = useRef<any>();
    const mapContainerRef = useRef<HTMLDivElement>(null);
    React.useImperativeHandle(ref, () => ({
      setCenterBySelection: setCenterBySelection,
      handleInfoWindowClose: handleInfoWindowClose,
      scrollToMapContainer: scrollToMapContainer
    }));

    useEffect(() => {
      // scroll to map on clicking  view as map button
      if (mapContainerRef.current && showOfficeDetails && address && service) {
        scrollToElement(mapContainerRef.current, 90);
      }
    }, [showOfficeDetails, address, service]);

    useEffect(() => {
      if (!officeData?.length) {
        setShowOfficeDetails(false);
      } else {
        setShowOfficeDetails(true);
      }
    }, [officeData]);

    /**
     * Sets the map center based on the selected location.
     * @param {Object} selectedLocation - The selected location object containing latitude and longitude.
     * @param {number} selectedLocation.lat - The latitude coordinate of the selected location.
     * @param {number} selectedLocation.lng - The longitude coordinate of the selected location.
     * @returns {void}
     */
    const setCenterBySelection = (selectedLocation: any) => {
      googleMapContainerRef.current?.setCenterOnSelection(selectedLocation);
    };

    const handleInfoWindowClose = () => {
      googleMapContainerRef?.current?.handleInfoWindowClose();
    };
    // // scroll to office map and list container on find-office button click
    const scrollToMapContainer = () => {
      if (mapContainerRef.current) {
        scrollToElement(mapContainerRef.current);
      }
    };
    // scroll to office map and list container
    const scrollToElement = (element: HTMLElement | null, offset = 0) => {
      if (element) {
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY - offset;
        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth'
        });
      }
    };

    /**
     * Handles the click event when an office is clicked on the map.
     * Redirects the user to the office details page based on the office's practice.
     * @param {Object} office - The office object containing information about the clicked office.
     * @param {string} office.practice - The practice associated with the office.
     * @returns {void}
     */

    return (
      <div id={id} className="w-full">
        <Container fixed className="mx-auto">
          <div
            ref={mapContainerRef}
            className="flex w-full flex-col-reverse lg:flex-row relative lg:gap-4 transition-all will-change-[height]"
          >
            <div
              className={`lg:w-1/2  grow transition-opacity duration-0 lg:opacity-100  pt-5 pb-24  lg:py-10 lg:block ${showOfficeDetails ? 'opacity-0 w-0 h-0 lg:h-auto lg:w-1/2  hidden' : 'opacity-100 pointer-events-auto w-full'}`}
            >
              {loading && (
                <div className="flex items-center justify-center mb-16 sm:mb-0">
                  <CircularProgress
                    variant="indeterminate"
                    size={40}
                    sx={{
                      color: '#808080',
                      marginTop: '10rem',
                      animationDuration: '1000ms',
                      position: 'absolute',
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round'
                      }
                    }}
                    thickness={4}
                  />
                </div>
              )}
              <OfficesList
                officeData={officeData}
                loading={loading}
                officePageData={officePageData}
                selectedService={selectedService}
                isFindAnOfficePage={true}
              />
            </div>
            <div
              className={`lg:w-1/2  relative lg:!opacity-100  transition-opacity duration-0 ${showOfficeDetails ? 'opacity-100 pointer-events-auto w-full' : 'opacity-0 pointer-events-none w-0 h-0 lg:!h-auto'}`}
            >
              <div className="office-map-wrap right-edge-img-wrap lg:sticky top-0 flex h-[25.625rem] lg:h-full lg:max-h-screen lg:min-h-[400px] transition-all will-change-[height]">
                <OfficeMap
                  ref={googleMapContainerRef}
                  offices={officeData}
                  officePageData={officePageData}
                  initialPlace={initialPlace}
                  selectedService={selectedService}
                  isFindAnOfficePage={true}
                  address={address}
                />
              </div>
            </div>

            {officeData?.length > 0 && (
              <div
                className={`lg:hidden sticky self-end flex-shrink top-[70px] z-50 mt-3 ${showOfficeDetails ? 'mb-2 mt-2 sm:mb-[-106px] sm:mt-12' : ''} `}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  disableFocusRipple
                  className="icon-btn-movement"
                  onClick={handleButtonClick}
                  endIcon={
                    <Icon
                      iconName="rightArrow"
                      className="svg-icon icon-24 moveRightIcon flex items-center justify-center"
                    ></Icon>
                  }
                >
                  <span className="t-15 font-inter font-medium leading-snug">
                    {showOfficeDetails ? 'View as list' : 'View as map'}
                  </span>
                </Button>
              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
);

export default OfficeMapContainer;
