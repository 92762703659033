'use client';
import React, { useEffect } from 'react';
import { Slider, styled } from '@mui/material';

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';
const StageSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#0a84ff' : '#007bff',
  height: 5,
  padding: '15px 0',
  '&.MuiSlider-dragging': {
    '.MuiSlider-thumb': {
      transition: 'box-shadow ease 0.2s, left ease 0.2s, transform ease 0.2s'
    }
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: 'var(--ba-green-450)',
    boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
    border: '3px solid white',
    transition: 'box-shadow ease 0.2s, left ease 0.2s, transform ease 0.2s',
    '&.Mui-active': {
      transform: 'translate(-50%, -50%) scale(1.2)'
    },
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow
      }
    },
    '&:before': {
      boxShadow:
        '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)'
    }
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&::before': {
      display: 'none'
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? 'var(--white)' : 'var(--black)'
    }
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 6
  },
  '& .MuiSlider-mark': {
    height: '0'
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    boxShadow: 'inset 0px 0px 4px -2px var(--black)',
    backgroundColor: 'var(--ba-gray-425)'
  }
}));

export interface MarksProps {
  value: number;
  label: number;
}
interface RadiusSliderProps {
  radiusInMilesOptions: string[] | undefined;
  radiusLabel: string | null;
  stageValue: number | undefined;
  handleStageChange: (event: Event, newValue: number | number[]) => void;
  setMarks: (marks: MarksProps[] | undefined) => void;
}

/**
 * A component representing a slider for selecting radius options.
 * @param {Object} props - The props object containing necessary parameters.
 * @param {number[]} radiusInMilesOptions - An array of numbers representing the available radius options in miles.
 * @param {string} radiusLabel - The label to display above the slider indicating its purpose.
 * @param {number} stageValue - The current value of the slider indicating the selected radius stage.
 * @param {Function} handleStageChange - A function to handle changes in the selected radius stage.
 * @param {Function} setMarks - A function to set the marks for the slider.
 * @returns {JSX.Element} A JSX element representing the RadiusSlider component.
 */
const RadiusSlider = ({
  radiusInMilesOptions,
  radiusLabel,
  stageValue = 50,
  handleStageChange,
  setMarks
}: RadiusSliderProps) => {
  const marks = radiusInMilesOptions
    ?.map(Number)
    ?.sort((a, b) => a - b)
    ?.map((value, index) => ({ value: index, label: value }));

  useEffect(() => {
    setMarks(marks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Formats the value text for the range slider.
   * @param {number} value - The current value of the range slider.
   * @returns {string} The formatted value text.
   */
  const rangeValuetext = (value: number) => {
    if (!isNaN(value)) {
      return `${value}%`;
    }
  };

  return (
    <div className="flex flex-col min-w-0">
      <h2 className="t-16-17 mb-4 font-semibold color-ba-primary-black">
        {radiusLabel}
      </h2>
      <div className="flex min-w-0 mx-3 lg:mx-0">
        <StageSlider
          getAriaLabel={() => 'Stage'}
          value={Number(stageValue)}
          sx={{ color: '#757575' }}
          onChange={handleStageChange}
          valueLabelFormat={rangeValuetext}
          max={marks ? marks?.length - 1 : 0}
          marks={marks?.map((mark) => ({
            value: mark?.value,
            label: (
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: 700
                }}
              >
                {mark?.label}
              </span>
            )
          }))}
        />
      </div>
    </div>
  );
};

export default RadiusSlider;
